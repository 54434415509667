.card .card-header {
  padding: 32px 16px;
}

.card .card-header .row{
  text-align: center;
}

.card .card-header h5{
  text-overflow: ellipsis;
  color: #4a4a4a;
}

.card .card-header .status-btn{
  display: flex;
  justify-content: center;
  align-items: center;
}

.card .card-body{
  position: relative;
  padding: 32px;
}

.card .card-plan{
  padding: 32px;
}

.card-body-item + .card-body-item{
  margin-top: 32px;
}

.card-body-confirm{
  position: relative;
}

.card-body-item-status{
  position: absolute;
  top: 16px;
  right: 0;
  color:rgb(76, 175, 80) !important;
}

.card .card-body .card-body-pergunta{
  text-align: justify;
  font-size: 1.3rem;
  color: #4a4a4a;
}

.card .card-footer{
  display: flex;
  justify-content: center;
}
.card-body-buttons{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
}

.card .footer2{
  justify-content: flex-end;
}

.card .card-footer .button{
  font-size: 0.7rem !important
}

.button + .button{
  margin-left: 8px;
}

.nav-buttons {
  margin-top: 16px;
  display: flex;
  justify-content:center;
  align-items: center;
}

.nav-label {
  text-align: center;
  min-width: 50px;
}

.card-body-confirm{
  margin-top: 16px;
  padding: 16px 0;
  border-bottom: 1px solid rgba(0,0,0,.125)
}
.float-button{
  position: absolute;
  right: 16px;
  top: 16px;
}

.status-button {
  border-bottom: 1px solid #66bb6a !important;
  color:#66bb6a !important;
}

.row + .row{
  margin-top: 24px;
}

.col-6, .col-md{
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 8px;
}

@media (max-width: 600px) {
  .card .card-body{
    padding: 0 16px;
  }

  .card .card-plan{
    margin-top: 16px;
    padding: 0 16px;
  }

}
