.container-login{
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.avatar-box {
  display: flex;
  justify-content: center;
}

.avatar-box img {
  width: 100px;
  height: 100px;
}

.form {
  width: 100% !important;
  margin-top: 8px !important;
}

input[type=password]{
  height: 55px !important;
}

.submit {
  margin: 24px 0px 16px !important;
}
