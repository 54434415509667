.footer {
  margin-top: 32px;
  margin-bottom: 32px;
}

.footer-infos{
  display: flex;
  justify-content: center;
}

.link{
  color: #777;
}
