.mycard {
  display: flex;
  justify-content: center;
  margin: 0 16px;
  min-height: 350px;
}

.mycontent {
  max-width: 100%;
  width: 800px;
  height: 100%
}

.modal-home {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
}

.paper-home {
  background-color: white;
  border: 2px solid #000;
  padding: 50px 32px;
}

.inadequado {
  background-color: rgb(244, 67, 54) !important;
  color:#fff !important;
  font-size: 12px !important;
}

.dev {
  background-color: rgb(255, 152, 0) !important;
  color:#fff !important;
  font-size: 12px !important;
}

.adequado {
  background-color: rgb(33, 150, 243) !important;
  color:#fff !important;
  font-size: 12px !important;
}

.muitobom {
  background-color: rgb(25, 118, 210) !important;
  color:#fff !important;
  font-size: 12px !important;
}

.excelente {
  background-color: rgb(76, 175, 80) !important;
  color:#fff !important;
  font-size: 12px !important;
}

/* Estrutura */
.input-container {
  position: relative;
}

.input-container + .input-container{
  margin-top: 32px;
}

.input-container input[type=text], input[type=password], textarea{
  border: 0;
  color: #848484;
  border-bottom: 0.5px solid #d2d2d2;
  outline: none;
  transition: .2s ease-in-out;
  box-sizing: border-box;
}

.input-container label {
  top: 0;
  left: 0; right: 0;
  color: #848484;
  display: flex;
  align-items: center;
  position: absolute;
  font-size: 1rem;
  cursor: text;
  transition: .2s ease-in-out;
  box-sizing: border-box;
}
.input-container input,
.input-container label {
  width: 100%;
  height: 3rem;
  font-size: 1rem;
}
.input-container textarea{
  width: 100%;
  font-size: 1rem;
}
/* Interation */
.input-container input:valid,
.input-container textarea:focus,
.input-container input:focus {
  border-bottom: 1px solid #1441a2;
}

.input-container input:valid + label,
.input-container textarea:focus + label,
.input-container input:focus + label {
  color: #1441a2;
  font-size: .8rem;
  top: -30px;
  pointer-events: none;
}
