.progress-outer {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  padding: 3px;
  text-align: center;
  background-color: #f4f4f4;
  border: 1px solid #dcdcdc;
  color: #fff;
  border-radius: 4px;
}

.progress-inner {
  min-width: 20%;
  white-space: nowrap;
  overflow: hidden;
  padding: 5px;
  border-radius: 4px;
  background-color: #1441a2;
}

.progress-success {
  background-color: #66bb6a;
}
