@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
header#main-header {
    background: #1441a2;
    height: 90px;
    border-bottom:1px solid #ddd;
    margin-bottom: 16px;
}

header#main-header .header-content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 16px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    color:#1341a3 !important
}

.my-icon {
  color:#fff !important
}

header#main-header .header-content img{
  height: 55px;
}

.header-item{
  display: flex;
  justify-content: center;

}

.menu-item{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  color: #fff;
  padding-right: 16px;
  line-height: 1;
}

.menu-item:hover{
  text-decoration: underline;
  color: #fff;
}

.dropdown-item{
  text-decoration: none;
}

.dropdown-menu{
  margin: .125rem -118px 0;
}

.footer {
  margin-top: 32px;
  margin-bottom: 32px;
}

.footer-infos{
  display: flex;
  justify-content: center;
}

.link{
  color: #777;
}

.container-login{
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.avatar-box {
  display: flex;
  justify-content: center;
}

.avatar-box img {
  width: 100px;
  height: 100px;
}

.form {
  width: 100% !important;
  margin-top: 8px !important;
}

input[type=password]{
  height: 55px !important;
}

.submit {
  margin: 24px 0px 16px !important;
}

.progress-outer {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  padding: 3px;
  text-align: center;
  background-color: #f4f4f4;
  border: 1px solid #dcdcdc;
  color: #fff;
  border-radius: 4px;
}

.progress-inner {
  min-width: 20%;
  white-space: nowrap;
  overflow: hidden;
  padding: 5px;
  border-radius: 4px;
  background-color: #1441a2;
}

.progress-success {
  background-color: #66bb6a;
}

.empty-list-content{
  display: flex;
  justify-content: center;
  margin: 32px 0 !important;
}



.mycard {
  display: flex;
  justify-content: center;
  margin: 0 16px;
  min-height: 350px;
}

.mycontent {
  max-width: 100%;
  width: 800px;
  height: 100%
}

.modal-home {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
}

.paper-home {
  background-color: white;
  border: 2px solid #000;
  padding: 50px 32px;
}

.inadequado {
  background-color: rgb(244, 67, 54) !important;
  color:#fff !important;
  font-size: 12px !important;
}

.dev {
  background-color: rgb(255, 152, 0) !important;
  color:#fff !important;
  font-size: 12px !important;
}

.adequado {
  background-color: rgb(33, 150, 243) !important;
  color:#fff !important;
  font-size: 12px !important;
}

.muitobom {
  background-color: rgb(25, 118, 210) !important;
  color:#fff !important;
  font-size: 12px !important;
}

.excelente {
  background-color: rgb(76, 175, 80) !important;
  color:#fff !important;
  font-size: 12px !important;
}

/* Estrutura */
.input-container {
  position: relative;
}

.input-container + .input-container{
  margin-top: 32px;
}

.input-container input[type=text], input[type=password], textarea{
  border: 0;
  color: #848484;
  border-bottom: 0.5px solid #d2d2d2;
  outline: none;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  box-sizing: border-box;
}

.input-container label {
  top: 0;
  left: 0; right: 0;
  color: #848484;
  display: flex;
  align-items: center;
  position: absolute;
  font-size: 1rem;
  cursor: text;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  box-sizing: border-box;
}
.input-container input,
.input-container label {
  width: 100%;
  height: 3rem;
  font-size: 1rem;
}
.input-container textarea{
  width: 100%;
  font-size: 1rem;
}
/* Interation */
.input-container input:valid,
.input-container textarea:focus,
.input-container input:focus {
  border-bottom: 1px solid #1441a2;
}

.input-container input:valid + label,
.input-container textarea:focus + label,
.input-container input:focus + label {
  color: #1441a2;
  font-size: .8rem;
  top: -30px;
  pointer-events: none;
}

.card .card-header {
  padding: 32px 16px;
}

.card .card-header .row{
  text-align: center;
}

.card .card-header h5{
  text-overflow: ellipsis;
  color: #4a4a4a;
}

.card .card-header .status-btn{
  display: flex;
  justify-content: center;
  align-items: center;
}

.card .card-body{
  position: relative;
  padding: 32px;
}

.card .card-plan{
  padding: 32px;
}

.card-body-item + .card-body-item{
  margin-top: 32px;
}

.card-body-confirm{
  position: relative;
}

.card-body-item-status{
  position: absolute;
  top: 16px;
  right: 0;
  color:rgb(76, 175, 80) !important;
}

.card .card-body .card-body-pergunta{
  text-align: justify;
  font-size: 1.3rem;
  color: #4a4a4a;
}

.card .card-footer{
  display: flex;
  justify-content: center;
}
.card-body-buttons{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
}

.card .footer2{
  justify-content: flex-end;
}

.card .card-footer .button{
  font-size: 0.7rem !important
}

.button + .button{
  margin-left: 8px;
}

.nav-buttons {
  margin-top: 16px;
  display: flex;
  justify-content:center;
  align-items: center;
}

.nav-label {
  text-align: center;
  min-width: 50px;
}

.card-body-confirm{
  margin-top: 16px;
  padding: 16px 0;
  border-bottom: 1px solid rgba(0,0,0,.125)
}
.float-button{
  position: absolute;
  right: 16px;
  top: 16px;
}

.status-button {
  border-bottom: 1px solid #66bb6a !important;
  color:#66bb6a !important;
}

.row + .row{
  margin-top: 24px;
}

.col-6, .col-md{
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 8px;
}

@media (max-width: 600px) {
  .card .card-body{
    padding: 0 16px;
  }

  .card .card-plan{
    margin-top: 16px;
    padding: 0 16px;
  }

}

.title {
  text-align: center;
}

*{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html,body, #root{
  height: 100%;
}

body{
  background: #e5e6f0;
  -webkit-font-smoothing: antialiased;
}

body, input, button {
  font-family: Roboto, sans-serif;
}

.text-secondary {
  color:#909090 !important
}

.text-center {
  text-align: center;
}

