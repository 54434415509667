header#main-header {
    background: #1441a2;
    height: 90px;
    border-bottom:1px solid #ddd;
    margin-bottom: 16px;
}

header#main-header .header-content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 16px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    color:#1341a3 !important
}

.my-icon {
  color:#fff !important
}

header#main-header .header-content img{
  height: 55px;
}

.header-item{
  display: flex;
  justify-content: center;

}

.menu-item{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  color: #fff;
  padding-right: 16px;
  line-height: 1;
}

.menu-item:hover{
  text-decoration: underline;
  color: #fff;
}

.dropdown-item{
  text-decoration: none;
}

.dropdown-menu{
  margin: .125rem -118px 0;
}
